import { apiPost } from '@/utils/api';
import router from '../../router/index';

export const state = {
	authToken: localStorage.getItem('authToken') !== '' ? localStorage.getItem('authToken') : ''
}

export const getters = {
	getToken: (state) => state.authToken
}

export const mutations = {
	SET_AUTH_TOKEN(state, token) {
		state.authToken = token
	}
}

export const actions = {
	setAuthToken(context, payload) {
		localStorage.setItem('authToken', payload)
		context.commit('SET_AUTH_TOKEN', payload)
	},
	logout(context) {
		apiPost('/logout');
		localStorage.setItem('authToken', '')
		context.commit('SET_AUTH_TOKEN', '')
		router.push('/login')
	}
}