import { apiGet } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export const state = {
	tableData: null,
	totalItemsCount: null,
	currentPage: 1,
	sort: 'id',
	sortDesc: 1,	
}

export const mutations = {
	SET_TABLE_DATA(state, tableData) {
		state.tableData = tableData;
	},
	SET_TOTAL_ITEMS_COUNT(state, count) {
		state.totalItemsCount = count;
	},
	SET_CURRENT_PAGE(state, page) {
		state.currentPage = page;
	},
	SET_SORT(state, sort) {
		state.sort = sort;
	},
	SET_SORT_DESC(state, sortDesc) {
		state.sortDesc = sortDesc;
	},	
}

export const actions = {
	update(context, payload) {
		apiGet(`users/${payload.userId}/projects?page=${payload.page}&sort=${payload.sort}&sortDesc=${payload.sortDesc}`)
			.then((response) => {
				context.commit('SET_TABLE_DATA', response.data.data);
				context.commit('SET_CURRENT_PAGE', payload.page);
				context.commit('SET_SORT', payload.sort);
				context.commit('SET_SORT_DESC', payload.sortDesc);				
				context.commit('SET_TOTAL_ITEMS_COUNT', response.data.total);
			})
			.catch(error => errorPopup(error));
	}
}
