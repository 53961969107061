import { apiGet } from '@/utils/api'
import router from '../../router/index'

export const state = {
	userInfo: null
}

export const getters = {
	userInfo: (state) => state.userInfo
}

export const mutations = {
	UPDATE_USER_INFO(state, payload) {
		state.userInfo = payload
	},
	RESET_USER_INFO(state) {
		state.userInfo = null;
	}
}

export const actions = {
	refreshUserInfo({ commit }, redirectOn401 = true) {
		apiGet('user')
			.then((response) => {
				if (response.status === 200) {
						commit('UPDATE_USER_INFO', response.data)
				}
			})
			.catch((error) => {
				// axios errors are weird! at first 'error' seems like it is just a string, but it does actually have properties...
				// https://github.com/axios/axios/blob/v0.18.0/lib/core/enhanceError.js
				// https://nodejs.org/api/errors.html#errors_class_error
				commit('UPDATE_USER_INFO', null);
				if (error.response.status === 401 && redirectOn401) router.push('/login');
			})
	},
	setUserInfo({ commit }, payload) {
		commit('UPDATE_USER_INFO', payload);
	},
	resetUserInfo({ commit }) {
		commit('RESET_USER_INFO');
	},
}