export default [
	{
		path: '/',
		name: 'home',
		component: () => import('./views/pages/home'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./views/account/login'),
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: () => import('./views/account/sign-up'),
	},
	{
		path: '/request-password-reset',
		name: 'RequestPasswordReset',
		component: () => import('./views/account/request-password-reset'),
	},
	{
		path: '/reset-password/:resetKey/:email',
		name: 'ResetPassword',
		component: () => import('./views/account/reset-password'),
		props: true,
	},
	{
		path: '/verify/success',
		name: 'EmailVerificationSuccess',
		component: () => import('./views/account/email-verification-success'),
		props: true,
	},
	{
		path: '/verify/failure',
		name: 'EmailVerificationFailure',
		component: () => import('./views/account/email-verification-failure'),
		props: true,
	},
	{
		path: '/admin/dashboard/',
		name: 'AdminDashboard',
		component: () => import('./views/admin/dashboard'),
	},
	{
		path: '/client/home',
		name: 'ClientDashboardHome',
		component: () => import('./views/client/home'),
	},
	{
		path: '/client/pricing',
		name: 'ClientPricing',
		component: () => import('./views/client/pricing'),
	},	
	{
		path: '/client/projects',
		name: 'ClientProjects',
		component: () => import('./views/client/projects'),
	},
	{
		path: '/client/reports',
		name: 'ClientReports',
		component: () => import('./views/client/reports'),
	},
	{
		path: '/client/settings',
		name: 'ClientSettings',
		component: () => import('./views/client/settings'),
	},
	{
		path: '/client/support',
		name: 'ClientSupport',
		component: () => import('./views/client/support'),
	},
	{
		path: '/client/tags',
		name: 'ClientTags',
		component: () => import('./views/client/tags'),
	},
	{
		path: '/client/time-entries',
		name: 'ClientTimeEntries',
		component: () => import('./views/client/time-entries'),
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import('./views/pages/privacy'),
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import('./views/pages/terms'),
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('./views/pages/contact'),
	},
	{
		path: '*',
		name: 'Page-404',
		component: () => import('./views/pages/error-404'),
	}
]
