import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss";
import Sparkline from 'vue-sparklines';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueShepherd from 'vue-shepherd';
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_GOOGLE_ANALYTICS_TAG : null },
}, router);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.use(VueAxios, axios)

Sentry.init({
	Vue,
	dsn: "https://c8cd78ac55c74d58b2dd80fef22971c4@o410542.ingest.sentry.io/5284575",
	integrations: [
		new Integrations.BrowserTracing({
		routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		tracingOrigins: [process.env.VUE_APP_API_BASE_URL],
		}),
	],
	logErrors: true,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.component('apexchart', VueApexCharts)
Vue.use(require('vue-moment'));
Vue.use(require('vue-shortkey'));
Vue.use(VueShepherd);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
