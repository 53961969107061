import axios from 'axios'
import store from '@/state/store'

function setDefaults() {
	let token = '';
		
	if (localStorage.getItem('authToken') !== '') {
		token = localStorage.getItem('authToken')
	} else if (store.state.auth.authToken !== '') {
		token = store.state.auth.authToken
	}
	
	if (token !== null) {
		axios.defaults.headers.Authorization = `Bearer ${token}`
	} else {
		axios.defaults.headers.Authorization = `Bearer NO-TOKEN`
	}
	
	axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
}

export const apiPost = (url, data, config) => {
	setDefaults()
	return axios.post(url, data, config)
}

export const apiGet = (url, config) => {
	setDefaults()
	return axios.get(url, config)
}

export const apiDelete = (url, config) => {
	setDefaults()
	return axios.delete(url, config)
}

export const apiPatch = (url, config) => {
	setDefaults()
	return axios.patch(url, config)
}
