import Swal from "sweetalert2";

export default (error) => {
	if (typeof error.response === 'undefined') {
		Swal.fire({
			icon: "error",
			title: "Whoops!",
			html: 'Something went wrong.',
		});
	} else if (error?.response.data.message) {
		const errorMessage = error?.response.data.message ? error.response.data.message : false;
		Swal.fire({
			icon: "error",
			title: "Whoops!",
			html: errorMessage ? errorMessage : 'Something went wrong.',
		})
	}
}
